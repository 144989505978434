<template>
    <div class="metadataEntityData">
        <div class="data-table">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="数据库">
                    <el-input placeholder="数据库" v-model="dbData.dbkey" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="数据表">
                    <el-input placeholder="数据表" v-model="dbData.table_name"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="medium" @click="saveDataBase">
                        保存
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="handle-btn">
            <el-button type="primary" size="small" @click="saveEntityData">
                批量保存
            </el-button>
            <el-button type="primary" size="small" @click="syncDataBase">
                同步到数据库
            </el-button>
        </div>
        <div class="table-con">
            <el-table
                :data="tableData"
                stripe
                style="width: 100%"
                height="calc(100% - 35px)"
                @selection-change="handleSelectionChange"
            >
                >
                <el-table-column
                    type="selection"
                    width="55"
                >
                </el-table-column>
                <el-table-column
                    label="信息项名称"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.info_name }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="主键"
                >
                    <template slot-scope="scope">
                        <el-radio
                            v-model="radio"
                            :label="scope.row.info_id"
                            @change.native="getTemplateRow(scope.$index,scope.row)"
                        >
                            <i></i>
                        </el-radio>
                    </template>
                </el-table-column>
                <el-table-column
                    label="数据字段名称"
                >
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.data_name" placeholder="请输入内容"></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                    label="数据字段中文名"
                >
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.data_name_cn" placeholder="请输入内容"></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                    label="数据字段类型"
                >
                    <template slot-scope="scope">
                        <el-select
                            v-model="scope.row.data_type"
                            placeholder="请选择信息项类型"
                        >
                            <el-option
                                v-for="item in dataTypes"
                                :label="item.name_cn"
                                :value="item.name"
                                :key="item.id"
                            ></el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column
                    label="字段长度"
                >
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.data_length" placeholder="请输入内容"></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                    label="小数位"
                >
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.data_scale" placeholder="请输入内容"></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                    label="规则"
                >
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.data_rule" placeholder="请输入内容"></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                    label="说明"
                >
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.data_remarks" placeholder="请输入内容"></el-input>
                    </template>
                </el-table-column>
            </el-table>
            <div class="table-page">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pagetion.pageindex"
                    :page-sizes="pagetion.pagesizes"
                    :page-size="pagetion.pagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pagetion.total"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import util from '@/utils/util';
export default {
    components: {},
    props: {
        entityId: {
            type: String,
        },
    },
    data() {
        return {
            dbData: {},
            tableData: [],
            pagetion: {
                pageindex: 1,
                pagesize: 10,
                total: 0,
                pagesizes: [10, 20, 30, 40, 50, 100],
            },
            selectData: [], // 多选选中数据集合
            radio: '',
            dataTypes: [], // 数据字段类型
        };
    },
    watch: {
        entityId(val) {
            if (val) {
                this.entityId = val;
                this.getEntityData();
                this.getDataBase();
                this.getTypes();
            }
        },
    },
    computed: {},
    methods: {
        // 获取数据库信息
        getDataBase() {
            this.$axios
                .get('/interfaceApi/datacenter/metadata/entity/get/' + this.entityId)
                .then(res => {
                    if (res) {
                        this.dbData = res;
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 保存数据库信息
        saveDataBase() {
            this.$axios
                .post('/interfaceApi/datacenter/metadata/entity/save', this.dbData)
                .then(res => {
                    if (res) {
                        this.$message.success('保存成功');
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取数据字段
        getEntityData() {
            this.$axios
                .get('/interfaceApi/datacenter/metadata/entity/data/edit/getpage/' + this.entityId
                + '?pageindex=' + this.pagetion.pageindex + '&pagesize=' + this.pagetion.pagesize)
                .then(res => {
                    if (res) {
                        this.pagetion.total = res.total;
                        this.tableData = res.rows;
                        this.tableData.map(item => {
                            if (item.is_primary_key === 1) {
                                this.radio = item.info_id;
                            }
                        });
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 保存数据字段
        saveEntityData() {
            if (this.selectData.length < 1) {
                this.$message.warning('请选择要保存的数据');
                return;
            }
            this.$axios
                .post('/interfaceApi/datacenter/metadata/entity/data/edit/save/' + this.entityId, this.selectData)
                .then(res => {
                    if (res) {
                        this.$message.success('保存成功');
                        this.getEntityData();
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        //
        // 同步到数据库
        syncDataBase() {
            this.$axios
                .get('/interfaceApi/datacenter/metadata/entity/data/edit/sync/' + this.entityId)
                .then(res => {
                    if (res) {
                        this.$message.success('同步成功');
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 数据字段类型
        getTypes() {
            this.$axios
                .get('/interfaceApi/datacenter/metadata/type/edit/getpage?pageindex=1&pagesize=20')
                .then(res => {
                    if (res) {
                        this.dataTypes = res.rows;
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 切换页码
        handleCurrentChange(page) {
            this.pagetion.pageindex = page;
            this.getEntityData();
        },
        // 切换每页条数
        handleSizeChange(size) {
            this.pagetion.pagesize = size;
            this.getEntityData();
        },
        // 多选事件
        handleSelectionChange(val) {
            this.selectData = val;
        },
        // 单选事件
        getTemplateRow(index, row) {
            this.tableData = this.tableData.map(item => {
                item.is_primary_key = 0;
                return item;
            });
            row.is_primary_key = 1;
        },
        // 批量保存
        multiSave() {
            if (!this.entityId) {
                this.$message.warning('请先选择树节点');
                return;
            }
            if (this.selectData.length < 1) {
                this.$message.warning('请先勾选将要保存的数据');
                return;
            }
            this.$axios
                .post('/interfaceApi/datacenter/metadata/entity/info/edit/save/' + this.entityId, this.selectData)
                .then(res => {
                    if (res) {
                        this.$message.success('保存成功');
                        this.getEntityData();
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
    created() {
        if (!this.entityId) {
            // this.$message.warning('请先选择树节点');
            return;
        }
        this.getEntityData();
        this.getDataBase();
        this.getTypes();
    },
    mounted() {},
};
</script>
<style lang="stylus">
.metadataEntityData
    height 100%
    .handle-btn
        padding .1rem
    .table-con
        padding .1rem
        height calc(100% - 123px)
        .el-table
            border 1px solid #f0f0f0
            border-radius 2px
            thead
                color #022782
                background #EDF0F5
                th
                    padding .06rem 0
                    background #EDF0F5
                    text-align center
            tr
                td
                    padding .06rem 0
                    border-bottom 0
                    text-align center
                &.el-table__row--striped
                        background #f8fafd
        .table-page
            text-align right
            margin-top .1rem
    .data-table
        padding .1rem
        border-bottom 1px solid #E7E7E7
        .el-form-item
            margin-bottom 0
</style>